import type { stubType } from '@/entities/restaurantPlug/model/types'

export const convertStubType = (type: stubType) => {
  switch (type) {
    case 'FULL':
      return 'Сервис полностью'
    case 'WITHOUT_TIPS':
      return 'Только оплату без чаевых'
    case 'PAYMENT':
      return 'Только оплату'
    case 'SBP':
      return 'Только оплату СБП'
    default:
      return 'Не выбрано'
  }
}
