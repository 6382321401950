import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import store from '@/store'

const $host: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

const $authHost: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    accept: 'application/json',
  },
})

const authInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const accessToken: string | null = await localStorage.getItem('userTokenData')
  config.headers = config.headers ?? {}
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
};

$authHost.interceptors.request.use(authInterceptor)
$authHost.interceptors.response.use(
  response => response,
  (err) => {
    const {
      response: { status, data },
    } = err
    if (status === 403 || data.message === 'Unauthenticated.') {
      if (localStorage.getItem('refreshTokenData')) {
        store.dispatch('user/REFRESH_TOKEN')
      }
    }

    return Promise.reject(err)
  }
)

export { $host, $authHost }

