import { $authHost } from '@/services/api'
import { AxiosResponse } from 'axios'
import type { IPlug, IGetResult } from './../model/types'

export const getAll = async (): Promise<IPlug[]> => {
  const { data }: AxiosResponse<IGetResult> = await $authHost.get('/stub/all')
  return data.result
}

export const create = async (plug: IPlug): Promise<void> => {
  await $authHost.post('/stub',{
    stub_type: plug.stub_type,
    name: plug.name,
    shops: plug.shops
  })
}

export const del = async (id: number): Promise<void> => {
  await $authHost.delete(`/stub/${id}`)
}

export const update = async (plug: IPlug): Promise<void> => {
  await $authHost.put(`/stub/${plug.id}`, {
    stub_type: plug.stub_type,
    name: plug.name,
    shops: plug.shops
  })
}
